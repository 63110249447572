import { createClient } from 'pg-api'
import io from 'socket.io-client'
import socketio from '@feathersjs/socketio-client'

import { pinia } from './pinia'

const endpoint = import.meta.env.VITE_API_ENDPOINT || 'http://localhost:5000'
const socket = io(endpoint, { transports: ['websocket'] })

export const feathersClient = createClient(socketio(socket) as any, {
  path: 'auth/pge',
  storage: window.localStorage,
})

export const api = createPiniaClient(feathersClient as any, {
  pinia,
  idField: 'ID',
  // optional
  defaultLimit: 1000,
  ssr: false,
  whitelist: [],
  paramsForServer: [],
  skipGetIfExists: true,
  customSiftOperators: {},
  services: {
    'cc/authManagement': {
      idField: 'ID',
    },
    'cc/electionMetrics': {
      idField: 'CustomerAccount',
    },
    'cc/electrack': {
      idField: 'ID',
    },
    'cc/epmsComponent': {
      idField: 'ID',
    },
    'cc/epmsOrder': {
      idField: 'ID',
    },
    'epms/contact': {
      idField: 'ID',
    },
    'epms/customerShipTo': {
      idField: 'ItemNumber',
    },
    'epms/customer': {
      idField: 'Account',
    },
    'pge/elections': {
      idField: 'ID',
    },
    'pge/electrackAddresses': {
      idField: 'ID',
    },
    'pge/electrackMaps': {
      idField: 'ID',
    },
    'pge/menus': {
      idField: 'ID',
    },
    'pge/orders': {
      idField: 'ID',
    },
    'pge/userGroups': {
      idField: 'ID',
    },
    'pge/users': {
      idField: 'ID',
    },
    'pge/files': {
      idField: 'ID',
    },
  },
})

// temp fix for feathers-pinia issue
/*
  const clearHook = ({ result }: any) => {
    if (!result?.total && api.service('cc/lockouts').store.items.length ) {
      api.service('cc/lockouts').store.clearAll()
    }
  }
*/
api.service('cc/ballots').hooks({
  after: {
    remove: [
      ({ error, id }: any) => {
        if (id === 'removeAll' && !error)
          (api.service('pge/electionBallots') as any)?.store?.clearAll()
      },
    ],
  },
})
