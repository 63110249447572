import capitalize from 'lodash/capitalize'
import keyBy from 'lodash/keyBy'
import pick from 'lodash/pick'

const { api } = useFeathers()
const { getParties } = useStatic()
const { calcTotal } = useCalculator()
const { snackbarAction } = useSnackbar()

export const useOrderStore = defineStore({
  id: 'order',
  state: () => ({
    activeTab: 'product',
    backside: {
      confirm: false,
      required: false,
      selected: [] as any[],
    },
    election: null as any,
    files: null as any,
    grouping: null,
    jobNumber: '',
    loading: true,
    matrixes: [],
    options: {
      ballotType: null,
      language: null,
      testApproval: false,
      size: null,
    },
    orderId: null as any,
    orderItem: null as any,
    orderItemQtys: [] as any,
    party: undefined,
    product: undefined as undefined | any,
    quantities: [] as any[],
    stub: {
      checkbox1: null as any,
      checkbox2: null as any,
      confirm: false,
      index: 0,
    } as any,
  }),
  getters: {
    isEdit(state) {
      return !!state.orderItem
    },
    isMabs(state) {
      return state.product?.FileType === 'MABS'
    },
    isValid(state) {
      const tabs: Record<string, boolean> = {
        product: !!state.product,
        quantity: this.totalQuantities.items + this.totalQuantities.samples + this.totalQuantities.tests > 0,
        options: (!state.product?.Stub || state.stub.confirm)
          && (!(state.product?.Backside && state.backside.required) || state.backside.confirm)
          && ((state.product?.FileType === 'MABS' && state.options?.ballotType) || state.product?.FileType !== 'MABS'),
      }
      return tabs?.[state.activeTab] || false
    },
    showOptionsTab(state) {
      return state.product?.Stub || state.product?.Backside || state.product?.Multilingual || state.product?.Size
    },
    totalQuantities(state): Record<string, number> {
      return {
        items: calcTotal(state.quantities, 'Items', null),
        samples: calcTotal(state.quantities, 'Samples', null),
        tests: calcTotal(state.quantities, 'Tests', null),
      }
    },
  },
  actions: {
    async addProduct(cart: boolean) {
      const { orderId, cartDrawer } = storeToRefs(useCartStore())
      const id = cart ? orderId.value : this.orderId as number

      const productPayload = this.genProductPayload(id)

      const errorMsg = `Error adding product ${cart ? 'cart' : 'order'}`
      const successMsg = `Product added to ${cart ? 'cart' : 'order'}`
      await snackbarAction(async () => {
        if (cart) {
          await api.service('pge/orders').patch(id, {
            CustomerAccount: this.election?.CustomerAccount,
            ElectionID: this.election?.ID || 0,
          })
        }
        const orderProduct = await api.service('pge/orderProducts').create(productPayload)
        // set product id on qtys
        const quantityPayload = await this.genQuantityPayload(orderProduct.ID as any)
        await api.service('pge/orderQtys').create(quantityPayload)

        this.$reset()
        if (cart)
          cartDrawer.value = true
        else api.service('cc/epmsComponent').create({ Message: orderProduct.ID })
      }, successMsg, errorMsg)
    },
    calcEstimatedCost() {
      const { user } = storeToRefs(useAuthStore())
      const matrix = keyBy(this.product?.EstimatedCosts, 'County') as any
      const matrixGroup = user?.value?.SelectedCustomer?.County?.toLowerCase() || 'all'
      return Object.keys(this.totalQuantities).reduce((acc: any, key: string) => {
        const type = capitalize(key.slice(0, -1)) || null
        if (['General', 'Primary'].includes(this.election?.Type) || ['voters'].includes(key) || this.totalQuantities[key] <= 0)
          return acc
        const qty = this.totalQuantities[key]
        const minQty = matrix?.[matrixGroup]?.[`${type}MinQty`] || matrix?.all?.[`${type}MinQty`] || 0
        const minPrice = matrix?.[matrixGroup]?.[`${type}MinPrice`] || matrix?.all?.[`${type}MinPrice`] || 0
        const pricePer = matrix?.[matrixGroup]?.[`${type}PricePer`] || matrix?.all?.[`${type}PricePer`] || 0
        const cost = (minPrice + (qty > minQty ? (qty - minQty) * pricePer : 0))

        acc[`${type}Cost`] = !acc[`${type}Cost`] ? cost || 0 : acc[`${type}Cost`] + cost
        acc.TotalCost = !acc.TotalCost ? cost || 0 : acc.TotalCost + cost
        return acc
      }, { ItemCost: 0, SampleCost: 0, TestCost: 0, TotalCost: 0 })
    },
    genProductPayload(id: number, patch = false): any {
      return {
        ...(!patch) && {
          OrderID: id,
          ProductID: this.product?.ID,
          ItemType: this.product?.ItemType || null,
          FileType: `${(this.options?.language as any)?.charAt(0) || ''}${this.product?.FileType || ''}` || null,
          RequestFiles: false,
        },
        Description: [
          this.options?.size ? `${this.options?.size}"` : '',
          ...(this.options?.language ? [this.options.language] : []),
          this.product?.Description,
          this.options?.ballotType ? `- ${this.options?.ballotType}` : '',
        ].join(' ').trim(),
        ItemQuantity: this.totalQuantities?.items || 0,
        SampleQuantity: this.totalQuantities?.samples || 0,
        TestQuantity: this.totalQuantities?.tests || 0,
        ...this.calcEstimatedCost(),
        Party: this.party || null,
        BySheet: this.grouping === 'Sheet',
        TestApproval: !!this.options?.testApproval,
        BallotType: this.options?.ballotType || null,
        Language: this.options?.language || null,
        Size: this.options?.size || null,
        Checkbox1: this.stub?.checkbox1 || null,
        Checkbox2: this.stub?.checkbox2 || null,
        Backside1: this.backside?.required ? this.backside?.selected?.[0] : null,
        Backside2: this.backside?.required ? this.backside?.selected?.[1] : null,
        Backside3: this.backside?.required ? this.backside?.selected?.[2] : null,
        Backside4: this.backside?.required ? this.backside?.selected?.[3] : null,
        Backside5: this.backside?.required ? this.backside?.selected?.[4] : null,
      }
    },
    async genQuantityPayload(productID: number) {
      const payload = []
      for (const row of this.quantities) {
        if ((Number.parseInt(row.Items) + Number.parseInt(row.Samples) + Number.parseInt(row.Tests)) === 0)
          continue
        const qty = {
          OrderProductID: productID,
          ElectionMatrixID: row.ElectionMatrixID,
          BallotID: row.BallotID,
          Items: Number.parseInt(row.Items),
          Samples: Number.parseInt(row.Samples),
          Tests: Number.parseInt(row.Tests),
          StubMunicipality: this.product.Stub ? row.StubMunicipality : null,
          StubElectionName: this.product.Stub ? row.StubElectionName : null,
          StubLine2: this.product.Stub ? row.StubLine2 : null,
          StubDistrictInfo1: this.product.Stub ? row.StubDistrictInfo1 : null,
          StubDistrictInfo2: this.product.Stub ? row.StubDistrictInfo2 : null,
          StubDistrictInfo3: this.product.Stub ? row.StubDistrictInfo3 : null,
        }
        payload.push(qty)
      }
      return payload
    },
    resetOrder() {
      // delay clear till transition finishes
      setTimeout(() => this.$reset(), 200)
    },
    async startOrder(election: any, item = null as any, order = { orderId: null, jobNumber: '' }) {
      const customerParams = useAuthStore().user?.SelectedCustomerParameters || {}
      const matrixes = await api.service('pge/electionMatrixes').find({
        query: {
          Active: true,
          CustomerAccount: election.CustomerAccount,
          ElectionID: election.ID,
          Type: election.Type,
          $sort: {
            Party: 1,
            DistrictName: 1,
            Subdivision: 1,
            ElectionDistrict: 1,
          },
          $limit: 5000,
        },
      })

      const orderQty = item
        ? await api.service('pge/orderQtys').find({
          query: {
            Matrix: true,
            OrderProductID: item.ID,
          },
        })
        : { data: null }
      this.$patch({
        activeTab: item ? 'quantity' : 'product',
        backside: {
          required: item ? item?.Product?.Backside && item?.Backside1 !== null : false,
          confirm: item ? item?.Product?.Backside : false,
          selected: [
            item ? item?.Backside1 || null : customerParams?.UserDefined8 || null,
            item ? item?.Backside2 || null : customerParams?.UserDefined9 || null,
            item ? item?.Backside3 || null : customerParams?.UserDefined10 || null,
            item ? item?.Backside4 || null : customerParams?.UserDefined11 || null,
            item ? item?.Backside5 || null : customerParams?.UserDefined12 || null,
          ],
        },
        election,
        matrixes: matrixes.data,
        ...order,
        orderItem: item,
        orderItemQtys: orderQty.data,
        options: {
          ballotType: item?.BallotType || null,
          language: item?.Language || null,
          size: item?.Size || null,
        },
        party: item?.Party || undefined,
        product: item?.Product || undefined,
        stub: {
          confirm: item?.Product?.Stub || false,
          index: 0,
          checkbox1: item ? item?.Checkbox1 || null : customerParams?.UserDefined6 || null,
          checkbox2: item ? item?.Checkbox2 || null : customerParams?.UserDefined7 || null,
        },
      } as any)
      if (item)
        this.setQuantities()
    },
    setQuantities() {
      this.$patch({ loading: true })

      const electionType = this.election?.Type || ''
      const customer = useAuthStore().user?.SelectedCustomer?.CustName || null
      const county = useAuthStore().user?.SelectedCustomer?.County || null
      const state = useAuthStore().user?.SelectedCustomer?.State || null
      const lastStub = this.election?.LastStub || {}
      const defaultData = {
        // ID: null,
        ElectionMatrixID: null,
        CustomerAccount: '',
        DistrictKey: '',
        DistrictName: customer,
        Subdivision: '',
        ElectionDistrict: 1,
        Party: '',
        BySheet: false,
        Sheet: 1,
        Type: '',
        Voters: 0,
        Items: 0,
        Samples: 0,
        Tests: 0,
        BallotID: '',
        StubMunicipality: lastStub?.StubMunicipality || null,
        StubElectionName: lastStub?.StubElectionName || null,
        StubLine2: lastStub?.StubLine2 || null,
        StubDistrictInfo1: lastStub?.StubDistrictInfo1 || customer || null,
        StubDistrictInfo2: lastStub?.StubDistrictInfo2 || null,
        StubDistrictInfo3: lastStub?.StubDistrictInfo3 || null,
      }
      const useDefaultQty = (['Deck', 'Material'].includes(this.product?.ItemType || '') && !this.product.Stub) || this.product?.FileType === 'MABS' || state === 'PA'
      const orderQtysByMatrix = keyBy(this.orderItemQtys, 'ElectionMatrixID')
      const GenQtys = () => {
        // if primary/general, generate bySheet Lines
        const qtys = this.orderItemQtys?.length
          ? this.orderItemQtys
          : this.matrixes.length && !useDefaultQty
            ? this.matrixes
            : [defaultData]
        return this.grouping === 'Sheet' && ['General', 'Primary'].includes(electionType)
          ? Object.values(qtys.reduce((acc: any, val: any) => {
              if (this.party !== val.Party)
                return acc
              const sheet = val?.[`${capitalize(this.product.FileType)}Sheet`] || 1
              if (!acc[sheet]) {
                acc[sheet] = genQtyLine(val)
                acc[sheet].DistrictName = customer
                acc[sheet].StubDistrictInfo1 = ''
                acc[sheet].StubDistrictInfo2 = ''
                acc[sheet].StubDistrictInfo3 = ''
                acc[sheet].BySheet = true
              }
              else {
                acc[sheet].Voters += Number.parseInt(val.Voters)
              }
              return acc
            }, {}))
          : qtys.filter((val: any) => {
              return !this.party || this.party === (this.isEdit ? val?.Matrix?.Party : val?.Party)
            }).map((val: any) => genQtyLine(val))
      }

      const genQtyLine = (qtyLine: any) => ({
        ...defaultData,
        BallotID: this.isEdit ? qtyLine.Matrix.BallotID : qtyLine.BallotID || '',
        DistrictKey: this.isEdit ? qtyLine.Matrix.DistrictKey : qtyLine.DistrictKey || '',
        ID: qtyLine.ID,
        Party: this.isEdit ? qtyLine.Matrix.Party : qtyLine.Party || '',
        Sheet: qtyLine?.[`${capitalize(this.product.FileType)}Sheet`] || 1,
        StubMunicipality: ['General', 'Primary'].includes(electionType) ? county : lastStub?.StubMunicipality || null,
        StubElectionName: ['General'].includes(electionType)
          ? this.election?.Description?.split(' - ')[0] || 'General Election'
          : ['Primary'].includes(electionType) ? getParties()[this.party || 'Default'] : lastStub?.StubElectionName || null,
        StubLine2: ['General', 'Primary'].includes(electionType) ? '' : lastStub?.StubLine2 || null,
        StubDistrictInfo1: ['General', 'Primary'].includes(electionType) ? qtyLine.DistrictName : lastStub?.StubDistrictInfo1 || customer || null,
        StubDistrictInfo2: ['General', 'Primary'].includes(electionType)
          ? qtyLine.Subdivision
          : ['Primary', 'Special', 'Village'].includes(electionType)
              ? this.isEdit ? qtyLine.Matrix.DistrictName : qtyLine.DistrictName
              : lastStub?.StubDistrictInfo2 || null,
        StubDistrictInfo3: ['General', 'Primary'].includes(electionType) ? qtyLine.ElectionDistrict : lastStub?.StubDistrictInfo3 || null,
        ...pick(qtyLine, Object.keys(defaultData)),
        ...(this.matrixes.length && !useDefaultQty) && {
          ...pick(orderQtysByMatrix?.[qtyLine.ElectionMatrixID] || {}, Object.keys(defaultData)),
          ElectionMatrixID: (this.isEdit ? qtyLine.ElectionMatrixID : qtyLine.ID) || null,
          ...(this.isEdit) && pick(qtyLine?.Matrix || {}, Object.keys(defaultData)),
        },
      })

      const quantities = useDefaultQty && !this.orderItem
        ? [defaultData]
        : this.matrixes?.length || this.orderItemQtys?.length
          ? GenQtys()
          : [defaultData]
      this.$patch({
        loading: false,
        quantities,
      })
    },
    async updateProduct(cart: boolean) {
      const { orderId, cartDrawer } = storeToRefs(useCartStore())
      const id = cart ? orderId.value : this.orderId as number

      const productPayload = this.genProductPayload(id as number, true)

      const errorMsg = `Error updating ${cart ? 'cart' : 'product'}`
      const successMsg = `${cart ? 'Cart' : 'Product'} updated`
      this.$patch({ loading: true })
      await snackbarAction(async () => {
        await api.service('pge/orderProducts').patch(this.orderItem.ID, productPayload)
        // set product id on qtys
        const quantityPayload = await this.genQuantityPayload(this.orderItem.ID)
        await api.service('pge/orderQtys').remove(null, { query: { OrderProductID: this.orderItem.ID } })
        await api.service('pge/orderQtys').create(quantityPayload)

        this.resetOrder()
        if (cart)
          cartDrawer.value = true
        else await api.service('cc/epmsComponent').patch(this.orderItem.ID.toString(), {} as any)
      }, successMsg, errorMsg)
    },
  },
})
